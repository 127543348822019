import { Loading } from '@maverick/ui';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	padding: 60px 0;
	background: #f6f2e8;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.2rem;

	div {
		margin: 24px 0 0;
	}
`;

const Button = styled.a`
	font-family: TradeGothic, sans-serif;
	font-weight: bold;
	font-size: 1.2rem;
	text-transform: uppercase;
	display: block;
	margin: 28px 0 0 0px;
	text-align: center;
	border: 2px solid;
	padding: 10px 16px;
`;

const ButtonText = styled.div``;

interface GoToWrapperProps {
	redirectMethod: () => void;
	url?: string;
}
export const GoToWrapper: FC<GoToWrapperProps> = ({ redirectMethod, url }) => {
	useEffect(() => {
		redirectMethod();
	}, []);

	return (
		<Container>
			You are being redirected...
			<div>
				<Loading />
			</div>
			{!!url && (
				<>
					<Button href={url}>Order now</Button>

					<ButtonText>Click ORDER NOW to be redirected</ButtonText>
				</>
			)}
		</Container>
	);
};
