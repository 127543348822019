import {
	withDatasourceCheck,
	GetStaticComponentProps,
	LayoutServiceData,
	ComponentRendering,
	Link,
	ImageField,
	Image as SitecoreImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import {
	Dots,
	CarouselDirection,
	CarouselArrow,
	CarouselDots,
	CarouselContainer,
	CarouselBanner,
	clickWithEnterOrSpace,
} from '@maverick/cms';
import { CarouselResponse, SlideResponse } from './CarouselModel';
import { CarouselService } from 'src/services/Carousel.service';
import { GaEvent } from '@maverick/utils';

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const data = await CarouselService.GetSlides<CarouselResponse>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);
	return { slides: data.body.item.children.results };
};

type CarouselRenderingProps = ComponentProps & {
	slides: SlideResponse[];
	rendering: {
		fields: {
			ArrowImage: ImageField;
		};
	};
};

export const CarouselRendering = (props: CarouselRenderingProps): JSX.Element => {
	const slider = useRef<Slider>(null);
	const [isPlaying, setIsPlaying] = useState(true);

	const handleClickDotsControl = () => {
		setIsPlaying(!isPlaying);
		isPlaying ? slider.current!.slickPause() : slider.current!.slickPlay();
	};

	const handleClickDotsControlWhenEnterOrSpace = (ev: React.KeyboardEvent) => {
		if (ev.key == 'Enter' || ev.key === ' ') {
			ev.preventDefault();
			handleClickDotsControl();
		}
	};

	const handleClickNextArrow = () => slider.current?.slickNext();

	const handleClickPrevArrow = () => slider.current?.slickPrev();

	const Image = props.rendering?.fields?.ArrowImage?.value?.src ? (
		<SitecoreImage field={props.rendering?.fields?.ArrowImage} />
	) : undefined;

	const settings = {
		arrows: true,
		accessibility: true,
		dots: true,
		dotsClass: Dots,
		swipeToSlide: true,
		swipe: true,
		autoplay: true,
		autoplaySpeed: 10000,
		pauseOnHover: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: (
			<CarouselArrow direction={CarouselDirection.Right} arrowImage={Image} onClick={handleClickNextArrow} />
		),
		prevArrow: (
			<CarouselArrow direction={CarouselDirection.Left} arrowImage={Image} onClick={handleClickPrevArrow} />
		),
		appendDots: (dots: React.ReactNode) => (
			<CarouselDots
				onClick={handleClickDotsControl}
				onKeyDown={handleClickDotsControlWhenEnterOrSpace}
				isPlaying={isPlaying}
			>
				{dots}
			</CarouselDots>
		),
	};

	const handlePress = (ev: React.KeyboardEvent, name: string) => {
		clickWithEnterOrSpace(ev, () => GaEvent.CarouselClick(name));
	};

	return (
		<CarouselContainer id='carousel-outback'>
			<Slider {...settings} ref={slider}>
				{props?.slides?.map((item, i) => {
					const isFirst = i === 0;
					const fetchPriority = isFirst ? 'high' : 'low';
					const loading = isFirst ? 'eager' : 'lazy';

					return (
						<CarouselBanner
							key={item?.id}
							onClick={() => GaEvent.CarouselClick(item.name)}
							onKeyDown={(ev) => handlePress(ev, item.name)}
						>
							{item?.link?.jsonValue?.value?.href === '' ? (
								<img
									alt={item.image.alt}
									src={item.image.src}
									fetchpriority={fetchPriority}
									loading={loading}
								/>
							) : (
								<Link field={item?.link?.jsonValue?.value}>
									<img
										alt={item.image.alt}
										src={item.image.src}
										fetchpriority={fetchPriority}
										loading={loading}
									/>
								</Link>
							)}
						</CarouselBanner>
					);
				})}
			</Slider>
		</CarouselContainer>
	);
};

export default withDatasourceCheck()<CarouselRenderingProps>(CarouselRendering);
