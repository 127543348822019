import { Field, GraphQLRequestClient, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';

interface SitecoreRawCard {
	item: {
		children: SitecoreCardsRawChildren;
	};
}
export interface SitecoreCardRawProps {
	name: string;
	title: Field<string>;
	text: Field<string>;
	notes: Field<string>;
	link: {
		jsonValue:{
      value:LinkFieldValue
    }
	};
	image: {
		alt: string;
		src: string;
	};
	button: {
		text: string;
		url: string;
		target: string;
	};
	buttonWidth: { value: string };
	whiteFontColor: { value: boolean };
	variant: { value: string };
}
export interface SitecoreCardsRawChildren {
	cards: SitecoreCardRawProps[];
}

export const GetCardsList = async (dataSource: string): Promise<SitecoreCardsRawChildren> => {
	const result = await Request(dataSource, getCardsListGraphQL);

	return result.item.children;
};

const Request = async (dataSource: string, gql: string): Promise<SitecoreRawCard> => {
	const gqlClient = new GraphQLRequestClient(String(process.env.GRAPH_QL_ENDPOINT), {
		apiKey: process.env.SITECORE_API_KEY,
	});

	return await gqlClient.request<SitecoreRawCard>(gql, {
		datasource: dataSource,
	});
};

const getCardsListGraphQL = `
query GetItem($datasource: String!) {
  item(path: $datasource, language: "en") {
    children(first: 100) {
      results {
        name
        ... on _Card {
          title {
            value
          }
          text {
            value
          }
          notes {
            value
          }
          button {
            text
            target
            url
          }
          buttonWidth {
            value
          }
          image {
            alt
            src
          }
          link {
            jsonValue
          }
          whiteFontColor {
            value
          }
          variant {
            value
          }
        }
      }
    }
  }
}
`;
