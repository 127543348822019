import {
	Image as SitecoreImage,
	ImageField,
	withDatasourceCheck,
	GetStaticComponentProps,
	ComponentRendering,
	LayoutServiceData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { NavigationRendering, NavigationRoutes } from 'src/services/NavigationRoutes';
import { Location } from 'components/cms/Location';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NavigationProps } from '@maverick/entity';
import { OutbackHeader } from '@maverick/ui';

type RenderingProps = ComponentRendering & NavigationRendering;
export const getStaticProps: GetStaticComponentProps = async (
	rendering: RenderingProps,
	layoutData: LayoutServiceData
) => {
	const language = layoutData.sitecore.context.language!;
	return { items: await NavigationRoutes.Routes({ ...rendering, language }) };
};

type HeaderRenderingProps = ComponentProps & {
	items: NavigationProps;
	rendering: {
		fields: {
			Logo: ImageField;
			'Logo Mobile': ImageField;
		};
	};
};

const HeaderRendering = (props: HeaderRenderingProps): JSX.Element => {
	const Image = props.rendering?.fields?.Logo.value?.src ? (
		<SitecoreImage field={props.rendering?.fields?.Logo} />
	) : undefined;

	const ImageMobile = props.rendering?.fields['Logo Mobile']?.value?.src ? (
		<SitecoreImage field={props.rendering?.fields['Logo Mobile']} />
	) : undefined;

	const router = useRouter();

	return (
		<OutbackHeader
			linkComponent={Link}
			items={props.items}
			extra={<Location />}
			img={Image}
			imgMobile={ImageMobile}
			router={router}
		/>
	);
};

export default withDatasourceCheck()<HeaderRenderingProps>(HeaderRendering);
