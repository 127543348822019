import {
	Field,
	GetStaticComponentProps,
	LinkField,
	RichText,
	useSitecoreContext,
	withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ICardResponseProps, RewardsModalBlock, useDictionary } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';
import { LogoService, RewardsModalLogo } from '../../../services/Logo.service';

interface logo {
	src: string;
	alt: string;
}

type CmsRewardsFormModalProps = ComponentProps & {
	logo: logo;
	fields: {
		Title: Field<string>;
		'First Description': Field<string>;
		'Second Description': Field<string>;
		'Floating Placeholder': { value: boolean };
		'Signed Up Title': Field<string>;
		'Signed Up Text': Field<string>;
		'Signed Up Button': LinkField;
		'Welcome Back Title': Field<string>;
		'Welcome Back Text': Field<string>;
		'Welcome Back Button': LinkField;
	};
};

export const getStaticProps: GetStaticComponentProps = async () => {
	const data = await LogoService.GetLogo<RewardsModalLogo>();

	return { logo: data.body.item.Logo };
};

const CmsRewardsModalRendering = (props: CmsRewardsFormModalProps): JSX.Element => {
	const logo = props?.logo;
	const { sitecoreContext } = useSitecoreContext();
	const { dictionary } = useDictionary();
	const isExperienceEditor = !!sitecoreContext.pageEditing;

	const signedUpResponse: ICardResponseProps = {
		title: props?.fields?.['Signed Up Title'].value,
		text: !!props?.fields?.['Signed Up Text'].value ? <RichText field={props?.fields['Signed Up Text']} /> : <></>,
		button: {
			href: props.fields['Signed Up Button'].value.href ?? '',
			label: props.fields['Signed Up Button'].value.text ?? '',
			target: props.fields['Signed Up Button'].value.target ?? '_blank',
		},
	};

	const welcomeBackResponse: ICardResponseProps = {
		title: props?.fields?.['Welcome Back Title'].value,
		text: !!props?.fields?.['Welcome Back Text'].value ? (
			<RichText field={props?.fields['Welcome Back Text']} />
		) : (
			<></>
		),
		button: {
			href: props.fields['Welcome Back Button'].value.href ?? '',
			label: props.fields['Welcome Back Button'].value.text ?? '',
			target: props.fields['Welcome Back Button'].value.target ?? '_blank',
		},
	};

	return (
		<RewardsModalBlock
			isExperienceEditor={isExperienceEditor}
			pageTitle={sitecoreContext.route?.displayName ?? ''}
			logo={<img src={logo?.src ?? ''} alt={logo?.alt ?? ''} />}
			title={props?.fields?.Title?.value ?? ''}
			firstDescription={
				!!props?.fields?.['First Description']?.value ? (
					<RichText field={props?.fields['First Description']} />
				) : undefined
			}
			secondDescription={
				!!props?.fields?.['Second Description']?.value ? (
					<RichText field={props?.fields['Second Description']} />
				) : undefined
			}
			signedUpResponse={signedUpResponse}
			welcomeBackResponse={welcomeBackResponse}
			dictionary={dictionary}
		/>
	);
};

export default withDatasourceCheck()<CmsRewardsFormModalProps>(CmsRewardsModalRendering);
