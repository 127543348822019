import { FC, useState, useRef, useEffect } from 'react';
import { Slugify } from '@maverick/utils';
import { GoToWrapper } from './GoTo.wrapper';
import { useSelector } from 'react-redux';
import { AppState } from '@maverick/cms';
import { Config } from 'src/Config';
import { useRouter } from 'next/router';

export const GoToOrder: FC = () => {
	const location = useSelector((state: AppState) => state.location);
	const [url, setUrl] = useState<string>('');
	const router = useRouter();
	const routerReady = useRef<boolean>(false);

	useEffect(() => {
		let url = Config.Urls.Order;

		if (location.restaurant?.slug) {
			url += `/${location.restaurant.slug}`;
		} else if (location.restaurant?.id) {
			url += `/${location.restaurant.id}-${Slugify(location.restaurant.name)}`;
		}

		setUrl(url);
	}, []);

	useEffect(() => {
		routerReady.current = router.isReady;
	}, [router]);

	if (!url) return null;

	const redirectMethod = () => {
		const interval = setInterval(() => {
			if (routerReady.current) {
				window.location.replace(url);
				clearInterval(interval);
			}
		}, 100);
	};

	return <GoToWrapper redirectMethod={redirectMethod} url={url} />;
};
