import {
	RichText,
	Link as SitecoreLink,
	LinkField,
	Field,
	withDatasourceCheck,
	ImageField,
	FileField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { BackgroundPosition, Hero, HeroProps, TextAlign, BackgroundSize } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type HeroBannerRenderingProps = ComponentProps & {
	fields: {
		'Background Color': Field<string>;
		Desktop: ImageField;
		Mobile: ImageField;
		['Over Title']?: Field<string>;
		Title: Field<string>;
		Text?: Field<string>;
		Button?: LinkField;
		Overlay: Field<boolean>;
		'Text Background Contrast': Field<boolean>;
		'Store Badges': Field<boolean>;
		'Ios Link'?: LinkField;
		'Android Link'?: LinkField;
		'Background Position': Field<string>;
		'Background Size': Field<string>;
		'Video Desktop': FileField;
		'Video Mobile': FileField;
		'Button Variant': Field<string>;
	};
	params: {
		'Text Align': string;
	};
};

const HeroRendering = (props: HeroBannerRenderingProps): JSX.Element => {
	if (!props.fields) return <div></div>;

	const {
		['Over Title']: OverTitle,
		Title,
		Text,
		Desktop,
		Mobile,
		Button: Button,
		['Background Color']: BackgroundColor,
		['Text Background Contrast']: TextBackgroundContrast,
		['Store Badges']: StoreBadges,
		['Ios Link']: IosLink,
		['Android Link']: AndroidLink,
		['Video Desktop']: VideoDesktop,
		['Video Mobile']: VideoMobile,
		['Button Variant']: ButtonVariant,
	} = props.fields;

	const handleTextAlign = () => {
		switch (props?.params?.['Text Align']) {
			case TextAlign.Left:
				return TextAlign.Left;
			case TextAlign.Center:
				return TextAlign.Center;
			case TextAlign.Right:
				return TextAlign.Right;
			default:
				return TextAlign.Center;
		}
	};

	const handleBackgroundPosition = () => {
		switch (props?.fields?.['Background Position'].value) {
			case BackgroundPosition.Bottom:
				return BackgroundPosition.Bottom;
			case BackgroundPosition.Center:
				return BackgroundPosition.Center;
			case BackgroundPosition.Top:
				return BackgroundPosition.Top;
			default:
				return BackgroundPosition.Center;
		}
	};

	const handleBackgroundSize = () => {
		switch (props?.fields['Background Size']?.value) {
			case BackgroundSize.Cover:
				return BackgroundSize.Cover;
			case BackgroundSize.Contain:
				return BackgroundSize.Contain;
			default:
				return BackgroundSize.Cover;
		}
	};

	const heroProps: HeroProps = {
		bgColor: BackgroundColor?.value,
		bgDesktop: Desktop?.value?.src,
		bgMobile: Mobile?.value?.src,
		overTitle: !!OverTitle?.value ? <RichText field={OverTitle} /> : undefined,
		title: !!Title.value ? <RichText field={Title} /> : undefined,
		text: !!Text?.value ? <RichText field={Text} /> : undefined,
		button: Button?.value?.href ? <SitecoreLink field={Button} /> : undefined,
		overlay: props?.fields.Overlay?.value ?? false,
		href: Button?.value?.href ?? undefined,
		textBackgroundContrast: TextBackgroundContrast?.value,
		textAlign: handleTextAlign(),
		storeBadges: StoreBadges?.value,
		iosLink: IosLink?.value?.href,
		androidLink: AndroidLink?.value?.href,
		backgroundPosition: handleBackgroundPosition(),
		backgroundSize: handleBackgroundSize(),
		videoDesktop: VideoDesktop?.value,
		videoMobile: VideoMobile?.value,
		buttonVariant: ButtonVariant?.value,
	};
	return <Hero {...heroProps} />;
};

export default withDatasourceCheck()<HeroBannerRenderingProps>(HeroRendering);
