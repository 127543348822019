import {
	ABTestManager,
	DineRewardsBanner,
	ICardResponseProps,
	RewardsBlock,
	useDictionary,
} from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';
import { Field, withDatasourceCheck, useSitecoreContext, RichText, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import { Storage } from '@maverick/utils';
import { ABTest } from '@maverick/entity';

type CmsRewardsFormProps = ComponentProps & {
	fields: {
		Title: Field<string>;
		'First Description'?: Field<string>;
		'Second Description'?: Field<string>;
		'Floating Placeholder': { value: boolean };
		'Signed Up Title'?: Field<string>;
		'Signed Up Text'?: Field<string>;
		'Signed Up Button'?: LinkField;
		'Welcome Back Title'?: Field<string>;
		'Welcome Back Text'?: Field<string>;
		'Welcome Back Button'?: LinkField;
		Variant?: Field<string>;
		Live: Field<boolean>;
		Threshold: { value: number };
		'AB Variant': Field<string>;
		'Expiration Date': Field<string>;
	};
};

const CmsRewardsFormRendering = (props: CmsRewardsFormProps): JSX.Element => {
	const { sitecoreContext } = useSitecoreContext();
	const { dictionary } = useDictionary();
	const [variant, SetVariantValue] = useState<string>();

	useEffect(() => {
		const abTestJson = ABTestManager.TokenValidation(props.rendering.uid);

		const expirationDate =
			props?.fields['Expiration Date']?.value === '0001-01-01T00:00:00Z'
				? ABTestManager.GetExpirationDateWhenValueIsEmpty()
				: new Date(props?.fields['Expiration Date']?.value);

		if (!props?.fields?.Live?.value || ABTestManager.ExpirationDateCheck(expirationDate)) {
			Storage.Local.Remove(`${ABTest.DRBanner}-${props.rendering.uid}`);
			SetVariantValue(props?.fields?.Variant?.value);
			return;
		}

		if (abTestJson == null) {
			const newAbTestJson = {
				variant: ABTestManager.RollDice(
					props?.fields?.Variant?.value ?? '',
					props?.fields['AB Variant']?.value,
					props?.fields?.Threshold?.value ?? 0
				),
				expirationDate: expirationDate,
			};

			Storage.Local.Set(`${ABTest.DRBanner}-${props.rendering.uid}`, newAbTestJson);
			SetVariantValue(newAbTestJson.variant);
		} else {
			SetVariantValue(abTestJson.variant);
		}
	}, []);

	const signedUpResponse: ICardResponseProps = {
		title: props?.fields['Signed Up Title']?.value ?? '',
		text: !!props?.fields['Signed Up Text']?.value ? <RichText field={props?.fields['Signed Up Text']} /> : <></>,
		button: {
			href: props.fields['Signed Up Button']?.value.href ?? '',
			label: props.fields['Signed Up Button']?.value.text ?? '',
			target: props.fields['Signed Up Button']?.value.target ?? '_blank',
		},
	};

	const welcomeBackResponse: ICardResponseProps = {
		title: props?.fields['Welcome Back Title']?.value ?? '',
		text: !!props?.fields['Welcome Back Text']?.value ? (
			<RichText field={props?.fields['Welcome Back Text']} />
		) : (
			<></>
		),
		button: {
			href: props.fields['Welcome Back Button']?.value.href ?? '',
			label: props.fields['Welcome Back Button']?.value.text ?? '',
			target: props.fields['Welcome Back Button']?.value.target ?? '_blank',
		},
	};
	if (!variant) return <div className={'display: none'}></div>;
	if (variant === 'Banner') return <DineRewardsBanner pageTitle={sitecoreContext.route?.displayName} />;
	if (variant === 'Compact') return <DineRewardsBanner pageTitle={sitecoreContext.route?.displayName} />;

	return (
		<RewardsBlock
			variant={variant === 'Secondary' ? 'secondary' : 'primary'}
			title={props?.fields?.Title?.value}
			firstDescription={
				!!props?.fields['First Description']?.value ? (
					<RichText field={props?.fields['First Description']} />
				) : undefined
			}
			secondDescription={
				!!props?.fields['Second Description']?.value ? (
					<RichText field={props?.fields['Second Description']} />
				) : undefined
			}
			pageTitle={sitecoreContext.route?.displayName}
			floatingPlaceholder={props?.fields['Floating Placeholder']?.value}
			dictionary={dictionary}
			signedUpResponse={signedUpResponse}
			welcomeBackResponse={welcomeBackResponse}
		/>
	);
};

export default withDatasourceCheck()<CmsRewardsFormProps>(CmsRewardsFormRendering);
